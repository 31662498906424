import { useState, useContext, useEffect, useMemo } from 'preact/hooks'
import { createContext } from 'preact'
import { form } from 'api'
import { setErrors } from 'utils/error'
import { useForm } from 'react-hook-form'
import { useWidget } from './widget.context'
import {
  hiddenFieldsValues,
  setRefererAddress,
} from 'utils/form-static-value-generator'
import { getKeyboardEventCode, KEYBOARD_MAP } from 'utils/keyboardEvents'
import { useLogic } from 'hooks/logic'
import { logicActionTypes, logicReverseActionTypes } from 'utils/types'
import { calculateValue } from 'hooks/logic/calculationFn'
import { useDispatch, useSelector } from 'react-redux'
import { resetSingleFieldsHistory } from 'actions/actionCreators'
import { difference } from 'ramda'
import AutoSave from './AutoSave'

const FormWidgetContext = createContext()

export const FORM_ID = 'formaloo-chatbot-form'

const FormWidgetProvider = ({ children, style }) => {
  const [currentStep, setStep] = useState(0)
  const [isLoading, showLoading] = useState(false)
  const [submitCode, setSubmitCode] = useState(null)
  const {
    formData: {
      formFields,
      formSlug,
      initialValues: valuesByAliasField,
      logic,
      logicMetadata,
      fields,
      formRedirectsAfterSubmit,
      shouldSaveDraft,
      generalSubmitData = {},
    },
    displayEndingPages,
    setSubmittedData,
    isFormSubmitted,
  } = useWidget()
  // Form hook
  const {
    unregister,
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    setValue,
    getValues,
    formState,
    reset,
    control,
    watch,
  } = useForm({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: valuesByAliasField,
  })

  const [hiddenFields, setHiddenFields] = useState(
    logicMetadata?.fields_hidden_by_default || [],
  )

  const getFieldFullData = slug => fields[slug] || null

  const dispatch = useDispatch()
  const history = useSelector(state => state.fieldsValueHistory) || {}
  const setHistory = (field, data) =>
    dispatch(resetSingleFieldsHistory(field, data))

  const [cityItems, setCityItems] = useState([])

  const {
    passedFields,
    passTheField,
    isTheConditionsMet,
    removeFromPassedField,
    keepFieldsUpToStep,
  } = useLogic(getValues)

  const formHasLogic = Boolean(logic && logic.length)

  const isLastPage = Boolean(formFields.length === currentStep - 1)
  const isFirstPage = Boolean(currentStep === 0)

  const callLogicAction = (rule, fieldSlug, isReversing) => {
    switch (rule.action) {
      case logicActionTypes.submit:
        submitForm(getValues())
        break
      case logicActionTypes.redirect:
        navigate()
        break
      case logicActionTypes.jump:
      case logicActionTypes.show:
        const jumpDestination = rule.args[0]
        if (jumpDestination.type === 'field') {
          const destinationPage = formFields.findIndex(
            page => page.slug === jumpDestination.identifier,
          )

          if (destinationPage > -1) {
            setStep(destinationPage + 1)
          }
          return
        }
        break

      case logicActionTypes.add:
      case logicActionTypes.multiply:
      case logicActionTypes.subtract:
      case logicActionTypes.divide:
        calculateValue({
          rule,
          setFieldValue: setValue,
          getFieldValue: getValues,
          getFieldFullData,
          setHistory,
          history,
          fieldSlug,
          isReversing,
        })
        break

      default:
        break
    }
  }

  const getLastCalculation = (rules = []) => {
    return rules.filter(rule =>
      [
        logicActionTypes.add,
        logicActionTypes.multiply,
        logicActionTypes.subtract,
        logicActionTypes.divide,
      ].includes(rule.action),
    )
  }

  const [fieldsLastAction, setFieldsLastAction] = useState({})

  const hasFieldLogic = fieldSlug =>
    logic?.findIndex(item => item.identifier === fieldSlug) !== -1

  const getFieldLogic = fieldSlug =>
    logic?.find(item => item.identifier === fieldSlug)

  const hideFields = slugs => {
    setHiddenFields(prevHidden => {
      return [...prevHidden, ...slugs]
    })
  }

  const showFields = slugs => {
    setHiddenFields(prevHidden => {
      const diff = difference(prevHidden, slugs)
      return diff
    })
  }

  const isFieldVisible = slug => !(hiddenFields ?? []).includes(slug)

  const getFieldLastActionReversed = fieldSlug => {
    const rules = fieldsLastAction[fieldSlug]

    if (!rules) {
      return []
    }

    return rules.map(rule => ({
      ...rule,
      action: ['show', 'hide'].includes(rule.action)
        ? logicReverseActionTypes[rule.action]
        : rule.action,
    }))
  }

  const saveFieldAction = (fieldSlug, rule) => {
    setFieldsLastAction(prev => {
      const prevRules = prev[fieldSlug] ?? []

      return {
        ...prev,
        [fieldSlug]: [...prevRules, rule],
      }
    })
  }

  const setFormErrors = formErrors => {
    const fieldsWithError = Object.keys(formErrors)

    fieldsWithError.forEach(f => {
      setValue(f, '')
    })

    const firstFieldWithError = formFields.findIndex(
      field => field.slug === fieldsWithError[0],
    )
    if (firstFieldWithError !== -1) {
      setStep(firstFieldWithError + 1)
      passTheField(fieldsWithError[0])
    }
  }

  const onError = error => {
    setFormErrors(error)
  }

  const onSuccessSubmit = response => {
    const {
      row: {
        calculation_score: totalScore,
        tracking_code: trackingCode,
        success_page: successPage,
        redirection_url: successRedirectUrl,
        submit_code: submitCode,
      },
    } = response

    setSubmittedData({
      totalScore,
      trackingCode,
      successPage,
      successRedirectUrl,
      submitCode,
    })

    if (formRedirectsAfterSubmit) {
      window.location.replace(formRedirectsAfterSubmit)
      return
    }
  }

  const submitForm = async data => {
    showLoading(true)
    const submitDraftData = submitCode ? { submit_code: submitCode } : {}
    const formData = {
      ...data,
      ...hiddenFieldsValues(),
      ...setRefererAddress(),
      ...submitDraftData,
    }
    try {
      const { data: theData, errors: formErrors } = await form().submit(
        formData,
        formSlug,
      )
      const row = theData.row
      showLoading(false)

      if (row) {
        setSubmitCode(row.submit_code)

        if (row?.status === 'submit') {
          onSuccessSubmit(theData)
          displayEndingPages()
          return
        }
      }
      setErrors(formErrors, setError)
      setFormErrors(formErrors.form_errors)
    } catch (error) {
      showLoading(false)

      setErrors(error, setError)
      setFormErrors(error.form_errors)
    }
  }
  const updateDraft = async (data, draftToken) => {
    const formData = {
      ...data,
      ...hiddenFieldsValues(),
      ...setRefererAddress(),
    }
    try {
      const { data: theData, errors: formErrors } = await form().updateDraft(
        formData,
        draftToken,
      )
      if (theData.row.status === 'draft') {
        return
      }
      setErrors(formErrors, setError)
      setFormErrors(formErrors.form_errors)
    } catch (error) {
      setErrors(error, setError)
      setFormErrors(error.form_errors)
    }
  }

  const saveDraft = async draftToken => {
    const draftSettings = !draftToken ? { status: 'draft' } : {}
    const formData = { ...getValues(), ...draftSettings }

    draftToken
      ? updateDraft(
          {
            ...formData,
            ...generalSubmitData,
          },
          draftToken,
        )
      : submitForm(formData)
  }

  const delayedNextStep = (delayTime = 700) => {
    setTimeout(() => {
      nextStep()
    }, delayTime)
  }

  const navigate = (direction = 1) => setStep(prev => prev + direction)

  const handleLogic = (currentField, isReversing = false, direction = 1) => {
    const fieldLogic = logic.find(
      l => l.type === 'field' && l.identifier === currentField.slug,
    )
    if (fieldLogic) {
      let noneOfConditionsMet = true

      for (const action of fieldLogic.actions) {
        if (isTheConditionsMet(action)) {
          noneOfConditionsMet = false
          callLogicAction(action, fieldLogic.identifier, isReversing)
          if (
            [
              logicActionTypes.jump,
              logicActionTypes.show,
              logicActionTypes.submit,
              logicActionTypes.redirect,
            ].includes(action.action)
          ) {
            passTheField(fieldLogic.identifier)
            return
          }
        }
      }

      if (noneOfConditionsMet && isReversing) {
        const lastActionsReversed = getLastCalculation(fieldLogic.actions)
        if (lastActionsReversed.length > 0) {
          for (const action of lastActionsReversed) {
            callLogicAction(action, fieldLogic.identifier, true)
          }
        }
      }
    }
    navigate(direction)
  }

  const nextStep = () => {
    const currentField = formFields[currentStep - 1]
    currentField && passTheField(currentField.slug)

    if (formHasLogic && !isFirstPage) handleLogic(currentField, false, 1)
    else navigate()
  }

  const prevStep = () => {
    const fieldToPassSlug = formFields[currentStep - 2]?.slug
    if (fieldToPassSlug) {
      removeFromPassedField(fieldToPassSlug)
      setValue(fieldToPassSlug, '')
    }
    if (formHasLogic) handleLogic(formFields[currentStep - 1], true, -1)
    else navigate(-1)
  }

  const goToStep = step => {
    if (step < formFields.length) {
      keepFieldsUpToStep(step)
      setStep(step)
    }
  }

  useEffect(() => {
    const allSlugs = formFields.map(field => field.slug)
    const allSlugsHandled = allSlugs.every(slug => passedFields.includes(slug))
    const shouldSubmit = formHasLogic ? isLastPage : allSlugsHandled
    if (shouldSubmit) {
      submitForm(getValues())
    }
  }, [passedFields, currentStep])

  const formProps = {
    // prevent submitting the form before last page by pressing enter
    onKeyPress: e => {
      const currentKeyCode = getKeyboardEventCode(e)

      if (currentKeyCode === KEYBOARD_MAP.enter.code && !e.shiftKey) {
        e.preventDefault()
      }
    },
  }

  return (
    <FormWidgetContext.Provider
      value={{
        register,
        handleSubmit,
        errors,
        setError,
        clearErrors,
        setValue: (name, value, options = {}) =>
          setValue(name, value, {
            shouldDirty: true,
            shouldValidate: true,
            ...options,
          }),
        unregister,

        getValues,
        control,
        watch,
        dirtyFields,
        currentStep,
        nextStep,
        passTheField,
        passedFields,
        delayedNextStep,
        hiddenFields,
        hasFieldLogic,
        getFieldLogic,
        showFields,
        hideFields,
        isFieldVisible,
        getFieldLastActionReversed,
        saveFieldAction,
        cityItems,
        setCityItems,
        prevStep,
        goToStep,
        isLoading,
      }}
    >
      <form className={style.multiStepForm} id={FORM_ID} {...formProps}>
        {children}
      </form>

      {shouldSaveDraft && (
        <AutoSave
          saveForm={saveDraft}
          formState={formState}
          initialData={valuesByAliasField}
          control={control}
          isFormSubmitted={isFormSubmitted}
          submitCode={submitCode}
        />
      )}
    </FormWidgetContext.Provider>
  )
}

const useFormWidget = () => {
  const context = useContext(FormWidgetContext)
  if (context === undefined) {
    throw new Error(`useFormWidget must be used within a FormWidgetProvider`)
  }
  return context
}
export { FormWidgetContext, FormWidgetProvider, useFormWidget }
